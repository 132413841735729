import {
  IonPage,
  IonIcon,
  IonText,
  IonFab, 
  IonFabButton, 
  IonItem,
  IonSkeletonText,
  IonLabel,
  IonThumbnail,
  IonFabList,
  IonContent,
  useIonAlert,
  useIonLoading,
  IonToolbar,
  IonButton,
  IonHeader,
  useIonModal,
  IonCard,
  IonTextarea,
  IonImg,
  IonRow,
  IonCol,
  IonCardContent,
  useIonToast
} from '@ionic/react';
import { refreshCircleOutline, menuSharp, navigateCircleOutline, locateOutline, mapOutline, storefrontSharp, logInOutline, logOutOutline, chatboxEllipsesOutline, arrowBackCircleOutline, arrowBackOutline, cameraOutline, trashOutline, checkmarkCircleOutline, closeCircleOutline, checkmarkCircle} from "ionicons/icons";
import React, {useEffect, useState} from 'react';
import { GoogleMap, useLoadScript, Marker, Circle, InfoWindow } from '@react-google-maps/api';
import { Geolocation } from '@capacitor/geolocation';
import { useDispatch, useSelector } from "react-redux";
import getDistance from 'geolib/es/getDistance';
import { Preferences } from '@capacitor/preferences'
import { RestApi } from './../services/Api';
import { CustomerAction } from "../data/state/CustomerReducer";
import { VisitAction } from "../data/state/VisitReducer";
import { useAuth } from "../data/sessions/Auth";
import { MapCustomer } from './../models/Modal';
import { Capacitor, Plugins } from "@capacitor/core";
import { Device } from '@capacitor/device';
import CheckPermissions from '../services/Permission';
import { LaunchNavigator, LaunchNavigatorOptions } from '@awesome-cordova-plugins/launch-navigator';
import { usePhotoGallery } from "../services/Camera";
import { CameraSource } from "@capacitor/camera";
import { useTranslation } from 'react-i18next';
import { getRoleMenu } from '../services/Library';
import { BackgroundGeolocation } from "../App";
import './Map.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if(hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const ModalNote: React.FC<any> = (props) => {
  const { deletePhoto, photos, takePhoto } = usePhotoGallery();
  const { t } = useTranslation(["general"]);
  const [alert] = useIonAlert();
  const dispatch = useDispatch();  
  const [presentLoading, dismissLoading] = useIonLoading();
  const [noteImage, setNoteImage] = useState<any>([]);
  const [imageList, setImageList] = useState<any>(props.visit.visit_image);
  const [deleteImage, setDeleteImage] = useState<any>([]);
  const [note, setNote] = useState<string>(props.visit.visitdet_note);
  const [baseUrl, setBaseUrl] = useState<any>("");
  const user = useSelector((state: any) => state.user.value);

  const massageAlert = async (status: string, body: string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [
        {
          text: "Ok",
          cssClass: "alert-button-confirm",
          handler: (d: any) => {},
        },
      ],
    });
  };

  const selectImageSource = async () => {
    if (
      photos.length >= 3 ||
      imageList.length >= 3 ||
      (imageList.length + photos.length) >= 3
    ) {
      massageAlert(
        "gagal",
        "Gambar Maximal 3 Jika Ingin Mengubah Gambar Harap Hapus Dengan Mengklik Gambar Tersebut"
      );
      return;
    }
    await takePhoto(CameraSource.Camera);
  };

  const postNote = async ()  => {
    if (note.length < 10) {
      massageAlert('error', "Catatan Minimal 10 Karakter");
      return;
    }
    await presentLoading({message:'Harap Tunggu'})
    const FormData = global.FormData;
    const formData = new FormData();
    photos.forEach((val: any, key: any) => {
      formData.append("image-" + Number(key), val.blobData);
    });
    deleteImage.forEach((val: any) => {
      formData.append("delete[]", val);
    });
    noteImage.forEach((val: any, key: any) => {
      formData.append("note-" + (Number(key)), val);
    });
    formData.append("custid", props.customer.customer_id);
    formData.append("visnote", note);
    await RestApi(
      `visit/update-note/${props.visit.visit_id}`,
      "post",
      formData,
      `application/x-www-form-urlencoded`
    )
      .then(async (res: any) => {
        if (res.data.code === 200) {
          dispatch(
            CustomerAction.updateCustomer({
              visitdet_note: note,
            })
          );
          dispatch(
            VisitAction.updateVisit({
              customer_id: props.customer.customer_id,
              visit_id: props.customer.visit_id,
              update: {
                visitdet_note: note,
              },
            })
          );
          if (photos.length) {
            photos.forEach((val: any) => {
              deletePhoto(val);
            });
          }
          props.onDismissDetail(true);
          massageAlert("succes", res.data.message);
          dismissLoading();
        } else {
          massageAlert("error", res.data.message);
          dismissLoading();
        }
      })
      .catch((error: any) => {
        massageAlert("error", error.message);
        dismissLoading();
      });
  }

  const confirmDelete = (file: any, check: boolean) =>
    alert({
      message: "Yakin ingin menghapus foto ini? ",
      backdropDismiss: false,
      buttons: [
        { text: "Tidak", cssClass: "alert-button-cancel", role: "cancel" },
        { text: "Ya", cssClass: "alert-button-confirm", role: "confirm" },
      ],
      onDidDismiss: (e) => {
        if (e.detail.role === "confirm") {
          if (check) {
            setDeleteImage([...deleteImage, file.visitdet_image_id]);
            delete noteImage[file.visitdet_image_id];
            setNoteImage(noteImage);
            setImageList(
              imageList.filter((obj: any) => {
                return obj.visitdet_image_id !== file.visitdet_image_id;
              })
            );
          } else {
            deletePhoto(file);
          }
        }
      },
    });

  useConstructor(async () => {
    const url = await Preferences.get({ key: "url" });
    setBaseUrl(url.value);
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e: any) => {
        if (photos.length) {
          for (let index = 0; index < photos.length; index++) {
            deletePhoto(photos[index]);
          }
        }
      });
    }
    if (photos.length) {
      for (let index = 0; index < photos.length; index++) {
        deletePhoto(photos[index]);
      }
    }
  });
  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar class="toolbar-detail-visit ion-no-padding">
          <IonButton
            color="light"
            slot="start"
            fill="clear"
            onClick={() => {
              if (photos.length) {
                for (let index = 0; index < photos.length; index++) {
                  deletePhoto(photos[index]);
                }
              }
              props.onDismissDetail(false);
            }}
          >
            <IonIcon color="light" slot="start" src={arrowBackOutline} />
          </IonButton>
          <IonItem className="ion-no-padding">
            <IonLabel className="ion-no-padding" color="light">
              <h4>
                <strong>Catatan & Upload Gambar</strong>
              </h4>
            </IonLabel>
            <IonIcon
              slot="end"
              size="medium"
              color="primary"
              icon={chatboxEllipsesOutline}
            />
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonItem>
            <IonTextarea
              placeholder="Isi Catatan"
              labelPlacement="floating"
              label="Catatan"
              name="note"
              onIonInput={(e: any) => setNote(e.detail.value!)}
              value={note}
              minlength={10}
            ></IonTextarea>
          </IonItem>
          <IonCard>
            <IonText>Maximal Upload 3 Gambar (Optional)</IonText>
            <IonItem onClick={() => selectImageSource()}>
              <IonText slot="start">Upload Gambar</IonText>
              <IonIcon slot="end" src={cameraOutline}></IonIcon>
            </IonItem>
          </IonCard>
          <IonButton expand="block" onClick={postNote}>
            Kirim
          </IonButton>
          <IonRow>
            {photos.length
              ? photos.reverse().map((value: any, key: any) => (
                  <IonCol size="12" key={key}>
                    <IonCard>
                      <IonCardContent>
                        <IonItem>
                          <IonLabel position="floating">
                            Isi Keterangan Gambar
                          </IonLabel>
                          <IonTextarea
                            placeholder="Isi Note"
                            name="note"
                            onIonChange={(e: any) => {
                              let noteArr = noteImage;
                              noteArr[key] = e.detail.value;
                              setNoteImage(noteArr);
                            }}
                            value={noteImage[key]}
                            minlength={10}
                          ></IonTextarea>
                        </IonItem>
                      </IonCardContent>
                      <IonFab vertical="bottom" horizontal="center">
                        <IonFabButton
                          onClick={() => confirmDelete(value, false)}
                          size="small"
                          color="light"
                        >
                          <IonIcon icon={trashOutline} color="danger"></IonIcon>
                        </IonFabButton>
                      </IonFab>
                      <IonImg src={value.webviewPath} />
                    </IonCard>
                  </IonCol>
                ))
              : ""}
            {imageList.length
              ? imageList.reverse().map((value: any, key: any) => (
                  <IonCol size="12" key={key}>
                    <IonCard>
                      <IonCardContent>
                        <IonItem>
                          <IonLabel position="floating">
                            Isi Keterangan Gambar
                          </IonLabel>
                          <IonTextarea
                            placeholder="Isi Note"
                            name="note"
                            onIonChange={(e: any) => {
                              let noteArr = noteImage;
                              noteArr[value.visitdet_image_id] = e.detail.value;
                              setNoteImage(noteArr);
                            }}
                            value={value.visitdet_image_note}
                            minlength={10}
                          ></IonTextarea>
                        </IonItem>
                      </IonCardContent>
                      <IonFab vertical="bottom" horizontal="center">
                        <IonFabButton
                          onClick={() => confirmDelete(value, true)}
                          size="small"
                          color="light"
                        >
                          <IonIcon icon={trashOutline} color="danger"></IonIcon>
                        </IonFabButton>
                      </IonFab>
                      <IonImg
                        src={
                          baseUrl +
                          "/image/visit/" +
                          user.company_id +
                          "/" +
                          value.visitdet_image_path
                        }
                      />
                    </IonCard>
                  </IonCol>
                ))
              : ""}
          </IonRow>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

const Map: React.FC<MapCustomer> = (props)  => {
  const dispatch = useDispatch();  
  const [presentToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();
  const { logOut } = useAuth()!;
  const [alert] = useIonAlert();
  const { t } = useTranslation(["general"]);
  const user = useSelector((state: any) => state.user.value);
  const getRole = getRoleMenu(user.role_menu, "customer");
  const [customer, setCustomer] = useState<any>([]);
  const [visit, setVisit] = useState<any>([]);
  const [onLoadInfoLoc, setOnLoadInfoLoc] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<any>([]);
  const [focusLoc, setFocusLoc] = useState<any>([]);
  const [onLoadInfoCust, setOnLoadInfoCust] = useState<boolean>(false);
  const handleDismissNote = (e: any) => {
    if (e) {
     props.onDismissDetail(); 
    }
    dismissNote();
  };

  const [presentNote, dismissNote] = useIonModal(ModalNote, {
    customer: props.customer,
    type: "note",
    visit: props.visit,
    onDismissDetail: handleDismissNote,
  });


  const checkLocation = () => {
    currentLocation()
    setFocusLoc({
      latitude  : coordinates?.coords?.latitude,
      longitude : coordinates?.coords?.longitude
    })
  };

  const massageAlert = async (status:string,body:string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{ text: 'Ok', cssClass: 'alert-button-confirm', handler: (d:any) => {} }],
    })
  }

  const mockLog = async () => {
    const info = await Device.getId();
    const application = await Device.getInfo();
    await RestApi('user/mocklog','post',{
      "imei" : info.identifier,
      "application" : application
    }).then((res:any) => {
      logOut()
      props.onDismissDetail()
      massageAlert('error',"Anda Menggunakan Fake GPS")
    })
    .catch((error:any)=>{
      logOut()
      props.onDismissDetail()
      massageAlert('error',"Anda Menggunakan Fake GPS!!!!!")
    })
  }

  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP??''
  })

  const guessLocation = (timeout:any) =>{
    return new Promise(function (resolve) {
      let id:any;
      let lastLocation:any;
      BackgroundGeolocation.addWatcher(
        {
          requestPermissions: true,
          stale: false,
          backgroundMessage: "Cancel to prevent battery drain.",
          backgroundTitle: "Tracking You.",
        },async function callback(location) {
          if(location?.simulated === true){
            await mockLog()
            resolve(lastLocation);
            BackgroundGeolocation.removeWatcher({id});
            return
          }
          lastLocation = location;
        }
      ).then(function retain_callback_id(the_id) {
          id = the_id;
      }).catch(()=>{
        massageAlert('error',"Aktifkan GPS!!!!!")
      });
      setTimeout(function () {
          resolve(lastLocation);
          BackgroundGeolocation.removeWatcher({id});
      }, timeout);
    });
  }

  const currentLocation = async () => {
    if (Capacitor.isNative) {
      CheckPermissions.requestFineLocation();
      guessLocation(50000)
    }
    const location = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
    });
    setCoordinates(location)
  }

  const updateLocation = () => {
    currentLocation()
    if(Math.trunc(coordinates.coords.accuracy) > 1000) {
      massageAlert('error',"Check Kembali Akuransi Kamu,  Akurasi : " + Math.trunc(coordinates.coords.accuracy))
      return;
    }
    alert({
      header: 'Anda yakin Merubah Master Lokasi?',
      message: "Master Lokasi akan berubah jika ok",
      cssClass: 'header-alert',
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'alert-button-cancel',
          role: 'cancel',
        },
        {
          text: 'OK',
          cssClass: 'alert-button-confirm',
          role: 'confirm',
          handler: async () => {
            await presentLoading({message:'Harap Tunggu'})
            currentLocation()
            await RestApi(`customer/update-location/${customer.customer_id}`,'post',{
              lat : coordinates.coords.latitude,
              lng :coordinates.coords.longitude
            }).then((res:any) => {
                if(res.data.code === 200) {
                  presentToast({
                    message: res.data.message,
                    duration: 1500,
                    icon: checkmarkCircleOutline,
                    color: "succes",
                  });
                  dismissLoading()
                  props.onDismissDetail()
                }else{
                  presentToast({
                    message: res.data.message,
                    duration: 1500,
                    icon: closeCircleOutline,
                    color: "danger",
                  });
                  dismissLoading()
                }    
            })
            .catch((error:any) => {
              presentToast({
                message: error.message,
                duration: 1500,
                icon: closeCircleOutline,
                color: "danger",
              });
              dismissLoading()
            })
          },
        },
      ]
    })
  }

  const resetLocation = () => {
    alert({
      header: 'Anda yakin Mereset Lokasi?',
      message: "Master Lokasi akan terhapus jika ok",
      cssClass: 'header-alert',
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'alert-button-cancel',
          role: 'cancel',
        },
        {
          text: 'OK',
          cssClass: 'alert-button-confirm',
          role: 'confirm',
          handler: async (data) => {
            await presentLoading({message:'Harap Tunggu'})
            currentLocation()
            await RestApi(`customer/reset-location/${customer.customer_id}`,'post',{
            }).then(async (res:any) => {
                if(res.data.code === 200) {
                  presentToast({
                    message: res.data.message,
                    duration: 1500,
                    icon: checkmarkCircle,
                    color: "success",
                  });
                  props.onDismissDetail()
                  dismissLoading()
                }else{
                  presentToast({
                    message: res.data.message,
                    duration: 1500,
                    icon: closeCircleOutline,
                    color: "danger",
                  });
                  dismissLoading()
                }    
            })
            .catch((error:any) => {
              presentToast({
                message: error.message,
                duration: 1500,
                icon: closeCircleOutline,
                color: "danger",
              });
              dismissLoading()
            })
          },
        },
      ]
    })
  }

  const navigateLocation = async () => {
    if(customer.customer_lat && customer.customer_lng) {
      let options: LaunchNavigatorOptions = {
        start:`${coordinates?.coords?.latitude},${coordinates?.coords?.longitude}`,
        startName:"driving",
      }
      LaunchNavigator.navigate([customer.customer_lat,customer.customer_lng], options);
    }else{
      massageAlert('error',"Lokasi Pelanggan Belum Di Buat") 
    }
  }

  const checkStatus = ()  => {
    if (customer.visit_hist_type === null) {
      return (<IonFabButton data-desc="Check In" onClick={() => visitCust(0)} ><IonIcon size='medium' color='danger' icon={logInOutline} /></IonFabButton>);
    }else if (!visit.visitdet_note) {
      return (
        <IonFabButton
          data-desc="Catatan"
          onClick={() => {
            presentNote();
          }}
        >
          <IonIcon
            size="medium"
            color="primary"
            icon={chatboxEllipsesOutline}
          />
        </IonFabButton>
      );
    }else if (customer.visit_hist_type === 0 ) {
      return (<IonFabButton data-desc="Check Out" onClick={() => visitCust(1)} ><IonIcon size='medium' color='success' icon={logOutOutline} /></IonFabButton>)
    }else if (customer.visit_hist_type === 1) {
      return (<IonFabButton data-desc="Check In" onClick={() => visitCust(0)} ><IonIcon size='medium' color='danger' icon={logInOutline} /></IonFabButton>);
    }
  }

  const visitCust = (type:number)  => {
    currentLocation()
    if(Math.trunc(coordinates.coords.accuracy) > 1000 ) {
      massageAlert('error',"Check Kembali Akuransi Anda,  Akurasi : " + Math.trunc(coordinates.coords.accuracy))
      return;
    }
    let longDistance = getDistance({
      latitude: Number(coordinates.coords.latitude),
      longitude: Number(coordinates.coords.longitude)
    },{
      latitude: Number(customer.customer_lat??coordinates.coords.latitude),
      longitude: Number(customer.customer_lng??coordinates.coords.longitude)
    })
    if(Math.trunc(longDistance) > Number(customer.customer_location_radius??50)) {
      massageAlert('error',`Lokasi Anda Di Luar Radius, Jarak Anda ${longDistance} Meter Dari Customer`) 
      return;
    }
    presentLoading({message:'Harap Tunggu'})
    RestApi(`visit/${visit.visit_id}`,'post',{
      vislat : coordinates.coords.latitude,
      vislng : coordinates.coords.longitude,
      custid : customer.customer_id,
      vistype : type
    }).then(async (res:any) => {
        if(res.data.code === 200) {
          massageAlert('succes',res.data.message) 
          dispatch(VisitAction.updateVisit({
            customer_id : customer.customer_id,
            visit_id: customer.visit_id,
            update : {
              visit_hist_type: type,
            } 
          }))
          dispatch(CustomerAction.updateCustomer({
            visit_hist_type : type,
            customer_lat : coordinates.coords.latitude,
            customer_lng : coordinates.coords.longitude,
          }))
          if (type === 1) {
            await Preferences.set({key: "visitOn", value: '' });
          }
          props.onDismissDetail()
          dismissLoading()
        }else{
          massageAlert('error',res.data.message) 
          dismissLoading()
        }    
    })
    .catch((error:any) => {
      massageAlert('error',error.message)
      dismissLoading()
    })
  }

  useConstructor( () => {
    currentLocation()
    setCustomer(props.customer)
    setVisit(props.visit)
  });

  useEffect(() => {
    setFocusLoc({
      latitude : customer.customer_lat,
      longitude : customer.customer_lng
    })
  },[visit,customer]);

  return (
    <IonPage>
      <IonFab vertical="top" horizontal="start" slot="fixed">
        <IonFabButton onClick={() => props.onDismissDetail()} color="dark">
          <IonIcon icon={arrowBackCircleOutline} />
        </IonFabButton>
      </IonFab>
      {isLoaded ? (
        <IonContent>
          <GoogleMap
            mapContainerClassName="google-map"
            center={{
              lat: Number(focusLoc.latitude ?? user.branchs.branch_lat),
              lng: Number(focusLoc.longitude ?? user.branchs.branch_lng),
            }}
            zoom={17}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              zoomControl: false,
              clickableIcons: false,
              keyboardShortcuts: false,
            }}
          >
            <Marker
              icon={{
                path: "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                fillColor: "yellow",
                fillOpacity: 0.9,
                scale: 2,
                strokeColor: "gold",
                strokeWeight: 2,
              }}
              position={{
                lat: Number(customer.customer_lat ?? user.branchs.branch_lat),
                lng: Number(customer.customer_lng ?? user.branchs.branch_lng),
              }}
              onClick={() => setOnLoadInfoCust(!onLoadInfoCust)}
            />

            <Circle
              center={{
                lat: Number(customer.customer_lat ?? user.branchs.branch_lat),
                lng: Number(customer.customer_lng ?? user.branchs.branch_lng),
              }}
              // required
              options={{
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
                radius: Number(customer.customer_location_radius ?? 50),
                zIndex: 1,
              }}
            />

            {onLoadInfoCust && (
              <InfoWindow
                onCloseClick={() => setOnLoadInfoCust(!onLoadInfoCust)}
                position={{
                  lat: Number(customer.customer_lat ?? user.branchs.branch_lat),
                  lng: Number(customer.customer_lng ?? user.branchs.branch_lng),
                }}
              >
                <IonText className="ion-no-padding">
                  <h4>
                    <IonIcon
                      size="small"
                      className="ion-no-padding"
                      src={storefrontSharp}
                    />
                    <strong>{customer.customer_name}</strong>
                  </h4>
                  <p>
                    <strong>{customer.customer_address1}</strong>
                  </p>
                </IonText>
              </InfoWindow>
            )}

            {coordinates && (
              <Marker
                position={{
                  lat: Number(
                    coordinates?.coords?.latitude ?? user.branchs.branch_lat
                  ),
                  lng: Number(
                    coordinates?.coords?.longitude ?? user.branchs.branch_lng
                  ),
                }}
                onClick={() => setOnLoadInfoLoc(!onLoadInfoLoc)}
              />
            )}

            {onLoadInfoLoc && (
              <InfoWindow
                onCloseClick={() => setOnLoadInfoLoc(!onLoadInfoLoc)}
                position={{
                  lat: Number(
                    coordinates?.coords?.latitude ?? user.branchs.branch_lat
                  ),
                  lng: Number(
                    coordinates?.coords?.longitude ?? user.branchs.branch_lng
                  ),
                }}
              >
                <IonText className="ion-no-padding">
                  <h4>
                    <IonIcon
                      size="small"
                      className="ion-no-padding"
                      src={locateOutline}
                    />
                    <strong>Posisi Kamu</strong>
                  </h4>
                  <p>
                    <strong>
                      Akurasi : {Math.trunc(coordinates?.coords?.accuracy)}
                    </strong>
                  </p>
                </IonText>
              </InfoWindow>
            )}
          </GoogleMap>
          <IonFab horizontal="end" vertical="bottom">
            <IonFabButton className="fab-up" size="small">
              <IonIcon size="small" icon={menuSharp} />
            </IonFabButton>
            {props.type === "customer" ? (
              <IonFabList side="top">
                <IonFabButton data-desc="Navigasi" onClick={navigateLocation}>
                  <IonIcon size="medium" color="dark" icon={mapOutline} />
                </IonFabButton>
                {getRole?.role_menu_edit === 1 && (
                  <>
                    <IonFabButton
                      data-desc="Set Lokasi"
                      onClick={updateLocation}
                    >
                      <IonIcon
                        size="medium"
                        color="success"
                        icon={locateOutline}
                      />
                    </IonFabButton>
                    <IonFabButton data-desc="Reset" onClick={resetLocation}>
                      <IonIcon
                        size="medium"
                        color="danger"
                        icon={refreshCircleOutline}
                      />
                    </IonFabButton>
                  </>
                )}

                <IonFabButton data-desc="Lokasi Ku" onClick={checkLocation}>
                  <IonIcon
                    size="medium"
                    color="tertiary"
                    icon={navigateCircleOutline}
                  />
                </IonFabButton>
              </IonFabList>
            ) : (
              <IonFabList side="top">
                <IonFabButton data-desc="Navigasi" onClick={navigateLocation}>
                  <IonIcon size="medium" color="dark" icon={mapOutline} />
                </IonFabButton>
                {checkStatus()}
                {visit.visitdet_note && (
                  <IonFabButton
                    data-desc="Catatan"
                    onClick={() => {
                      presentNote();
                    }}
                  >
                    <IonIcon
                      size="medium"
                      color="primary"
                      icon={chatboxEllipsesOutline}
                    />
                  </IonFabButton>
                )}
                <IonFabButton data-desc="Lokasi Ku" onClick={checkLocation}>
                  <IonIcon
                    size="medium"
                    color="tertiary"
                    icon={navigateCircleOutline}
                  />
                </IonFabButton>
              </IonFabList>
            )}
          </IonFab>
        </IonContent>
      ) : (
        <IonContent
          className="ion-padding"
          style={{ width: "100%", height: "100%" }}
        >
          <IonThumbnail style={{ width: "100%", height: "100%" }}>
            <IonSkeletonText
              style={{ width: "100%", height: "100%" }}
              animated={true}
            ></IonSkeletonText>
          </IonThumbnail>
        </IonContent>
      )}
    </IonPage>
  );
};
export default Map;
